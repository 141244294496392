//**********************************************//
//**************** // FRENCH // ****************//
//**********************************************//

import React from "react";
import ProjectsFr from "../components/ProjectCarousel/ProjetsFr";

const ProjectsPageFr = () => {
  return <ProjectsFr />;
};

export default ProjectsPageFr;
