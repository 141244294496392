//**********************************************//
//**************** // FRENCH // ****************//
//**********************************************//

import React from "react";
import AboutFr from "../components/About_fr/AboutFr";

const AboutPageFr = () => {
  return <AboutFr />;
};

export default AboutPageFr;
