//**********************************************//
//**************** // FRENCH // ****************//
//**********************************************//
import React from 'react';
import ContactFr from '../components/Contact_fr/ContactFr';

const ContactPageFr = () => {
  return <ContactFr />
}

export default ContactPageFr
